header {
    top: 0px;
    left: 0;
    right: 0;
    height: 64px;
    padding-top: 40px;
    position: fixed;
    z-index: 100;

    >span.menu {
        font-size: 20px;
        line-height: 24px;
        margin: 0px 24px;
    }

    .headerShadow {
        height: 0px;
        top: 64px;
        left: 24px;
        width: calc(100% - 48px);
        position: fixed;
    }
}
