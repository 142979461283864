@import 'index.scss';

body {
    background-color: #f3f3f3;
    color: #333333;
}

* {
    position: relative;
}

#root {
    > div.construction {
        font-size: 32px;
        line-height: 40px;
        font-weight: 200;
        color: black;
        margin: 32px 0px;
    }
}

.background {
    position: absolute;
    background: #00000000;
    height: 100px;
    width: 100%;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 100px;
        width: 0;
        height: 0;
        border-top: 300px solid #00000000;
        border-left: 100vw solid rgba(0,0,0,0);

        @media (max-width: $max-mobile-width) {
            border-top: 200px solid $main-color;
        }
    }
}

.profileImage {
    left: calc(50% - 150px);
    margin-top: 100px;
    height: 300px;
    width: 300px;
    border-radius: 50%;

    @media (max-width: $max-mobile-width) {
        margin-top: 50px;
    }
}

.center {
    margin: 0px auto;
    text-align: center;
}

.title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-top: 24px;
}

.subtitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    margin-top: 16px;
}

.link {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-top: 24px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
    border: none;

    button {
        border-radius: 50%;
//        border: -internal-light-dark(black, rgb(133, 133, 133));
        border: none;
        height: 160px;
        width: 160px;
        background: rgba($main-color, 0.2);//linear-gradient(271.11deg, white 0.92%, rgba(255, 255, 255, 0) 103.95%), linear-gradient(0deg, #f3f3f3, $main-color);
        margin: 20px;
        flex-grow: 0;
        flex-shrink: 0;  
        font-size: 16px;
        font-weight: 400;  
        cursor: pointer;
    }
}
